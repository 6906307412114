<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('teaGardenService.financial_report') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <!-- application type -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Application Type" vid="application_type" :rules="{required: true, min_value: 1}">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="application_type">
                            <template v-slot:label>
                                {{ $t('teaGardenPanel.application_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="search.application_type"
                                id="application_type"
                                :options="applicationType"
                                :state="errors[0] ? false : (valid ? true : null)">
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!-- fiscal year -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" :rules="{required: true, min_value: 1}">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="fiscal_year_id">
                            <template v-slot:label>
                                {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                id="fiscal_year_id"
                                :options="fiscalYearList"
                                :state="errors[0] ? false : (valid ? true : null)">
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!-- from date -->
                <b-col xs="12" sm="12" md="4">
                  <ValidationProvider name="Start date" vid="from_date" v-slot="{ errors }" :rules="{required: false}">
                    <b-form-group
                      label-for="from_date">
                      <template v-slot:label>
                        {{ $t('globalTrans.start_date') }}
                      </template>
                      <date-picker
                        id="from_date"
                        v-model="search.from_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </date-picker>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- to date -->
                <b-col xs="12" sm="12" md="4">
                  <ValidationProvider name="To date" vid="to_date" v-slot="{ errors }" :rules="{required: false}">
                    <b-form-group
                      label-for="to_date">
                      <template v-slot:label>
                        {{ $t('globalTrans.to_date') }}
                      </template>
                      <date-picker
                        id="to_date"
                        v-model="search.to_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </date-picker>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- service -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Service Name" vid="service_ids" :rules="{required:false}">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="year">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.service_name') }}
                      </template>
                      <v-select
                        id="processed_area"
                        v-model="search.service_ids"
                        multiple
                        :reduce="op => op.value"
                        :options="serviceList"
                        label="text"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :placeholder="$t('globalTrans.select')"
                        >
                      </v-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
              <!-- payment_type -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Payment Type" vid="payment_type" :rules="{required: false}">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="payment_type">
                            <template v-slot:label>
                                {{ $t('globalTrans.payment_type') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.payment_type"
                                id="payment_type"
                                :options="paymentTypeList"
                                :state="errors[0] ? false : (valid ? true : null)">
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <b-button size="sm" variant="primary" class="mt-20" type="submit">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </template>
        <!-- search section end -->
      </card>
      <b-row>
        <b-col md="12" v-if="showData">
          <body-card>
                <!-- table section start -->
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('teaGardenService.financial_report') }} {{ $t('globalTrans.list') }}</h4>
                </template>
                <template v-slot:headerAction>
                  <a href="javascript:" class="btn-add" @click="pdfExport">
                     {{ $t('globalTrans.export_pdf') }}
                  </a>
                  <a
                    style="color: white"
                    title="Excel"
                    class="btn-add btn-warning float-right"
                    href="javascript:"
                    @click="excelExport"
                  >
                    {{ $t('globalTrans.export_excel') }}
                  </a>
                </template>
                <template v-slot:body>
                  <b-overlay :show="loadingState">
                    <b-row>
                      <b-col>
                        <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                          <template v-slot:projectNameSlot>
                            {{ }}
                          </template>
                          {{ $t('teaGardenService.financial_report') }}
                        </list-report-head>
                      </b-col>
                    </b-row>
                    <template>
                      <div class="my-4 search-info">
                        <b-row>
                          <b-col md="3">
                            <span>{{ $t('teaGardenPanel.application_type') }} : </span>
                            <strong>{{ searchHeaderData.application_type ? getApplicationTypeName(searchHeaderData.application_type) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="3">
                            <span>{{ $t('globalTrans.fiscal_year') }} : </span>
                            <strong>{{ searchHeaderData.fiscal_year_id ? getFiscalYearName(searchHeaderData.fiscal_year_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="3">
                            <span>{{ $t('globalTrans.start_date') }} : </span>
                            <strong>
                              <template v-if="searchHeaderData.from_date">{{ searchHeaderData.from_date | dateFormat }}</template>
                              <template v-else>{{ $t('globalTrans.all') }}</template>
                            </strong>
                          </b-col>
                          <b-col md="3">
                            <span>{{ $t('globalTrans.end_date') }} : </span>
                            <strong>
                              <template v-if="searchHeaderData.to_date">{{ searchHeaderData.to_date | dateFormat }}</template>
                              <template v-else>{{ $t('globalTrans.all') }}</template>
                            </strong>
                          </b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col md="3">
                            <span>{{ $t('globalTrans.payment_type') }} : </span>
                            <strong>{{ searchHeaderData.payment_type ? getPaymentTypeName(searchHeaderData.payment_type) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="9">
                            <span>{{ $t('teaGardenConfig.service_name') }} : </span>
                            <strong>{{ searchHeaderData.service_ids.length ? getServiceNames(searchHeaderData.service_ids) : $t('globalTrans.all') }}</strong>
                          </b-col>
                        </b-row>
                      </div>
                      <div v-for="(item, index) in datas" :key="index">
                        <p class="my-2" style="font-weight: bold">
                          <span v-if="currentLocale === 'en'">
                            Application for {{item.application_type === 2 ? "Renewal" : "New Registration" }} of {{ getServiceName(item.service_id) }} ({{ getFiscalYearName(item.fiscal_year_id) }})
                          </span>
                           <span v-if="currentLocale === 'bn'">
                           {{ getServiceName(item.service_id) }} {{item.application_type === 2 ? "নবায়নের" : "নতুন নিবন্ধনের" }} জন্য আবেদন ({{ getFiscalYearName(item.fiscal_year_id) }})
                          </span>
                          </p>
                        <div class="table-responsive">
                          <b-table-simple class="tg mt-1" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                            <b-thead>
                              <b-tr>
                                <b-th class="text-center" width="8%">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center" width="10%">{{ $t('globalTrans.date') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenConfig.company_name') }}</b-th>
                                <b-th width="15%" class="text-center">{{ $t('teaGardenService.transaction_id') }}</b-th>
                                <b-th class="text-center" width="10%">{{ $t('externalUser.license_no') }}</b-th>
                                <b-th v-if="item.application_type === 2" class="text-center"  width="10%">{{ $t('teaGardenService.renew_fee') }}</b-th>
                                <b-th v-if="item.application_type === 2" class="text-center"  width="10%">{{ $t('teaGardenService.late_fee') }}</b-th>
                                <b-th v-if="item.application_type === 1" class="text-center"  width="15%">{{ $t('teaGardenService.new_registration_fee') }}</b-th>
                                <b-th class="text-center"  width="10%">{{ $t('teaGardenService.vat_fee') }} ({{$n(vat)}}%)</b-th>
                                <b-th class="text-center"  width="15%">{{ $t('teaGardenBtriService.total_amount') }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="item.items.length">
                                    <b-tr v-for="(itemData, index) in item.items" :key="index">
                                        <b-td class="text-center">{{$n(index + 1)}}</b-td>
                                        <b-td class="text-center">
                                          {{ itemData?.payment?.date | dateFormat }}
                                        </b-td>
                                        <b-td class="text-center">
                                          <span v-if="itemData.service_id === 30 || itemData.service_id === 31 || itemData.service_id === 32 || itemData.service_id === 33 || itemData.service_id === 34">
                                              {{ currentLocale === 'en' ? itemData.taggable?.company_name_en : itemData.taggable?.company_name_bn }}
                                          </span>
                                        </b-td>
                                        <b-td class="text-center">
                                          <span v-if="itemData?.payment?.check_no">Check No: {{ itemData?.payment?.check_no }}</span>
                                          <span v-if="itemData?.payment?.received_number">Receipt No: {{ itemData?.payment?.received_number }}</span>
                                          <span v-if="itemData?.payment?.transaction_number">{{ itemData?.payment?.transaction_number }}</span>
                                        </b-td>
                                        <b-td class="text-center">
                                          {{ itemData.registration_no }}
                                        </b-td>
                                         <b-td class="text-center">
                                           {{ $n(itemData?.payment?.service_amount) }}
                                        </b-td>
                                        <b-td class="text-center" v-if="itemData.application_type === 2">
                                           {{ $n(itemData?.payment?.late_fee_amount) }}
                                        </b-td>
                                         <b-td class="text-center">
                                           {{ $n(itemData?.payment?.vat_amount) }}
                                        </b-td>
                                        <b-td class="text-center">
                                           {{ $n(itemData?.payment?.amount) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                      <b-td colspan="5" class="text-right">
                                        {{$t('globalTrans.subtotal')}}
                                      </b-td>
                                      <b-td class="text-center">
                                        {{ $n(item?.sub_total_service_amount) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="item.application_type === 2">
                                        {{ $n(item?.sub_total_late_amount) }}
                                      </b-td>
                                      <b-td class="text-center">
                                        {{ $n(item?.sub_total_vat_amount) }}
                                      </b-td>
                                      <b-td class="text-center">
                                        {{ $n(item?.sub_total_amount) }}
                                      </b-td>
                                    </b-tr>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="8" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </div>
                      <div>
                        <h4 class="text-right">{{$t('globalTrans.grand_total')}} = {{ $n(total_amount) }}</h4>
                      </div>
                    </template>
                  </b-overlay>
                </template>
                <!-- table section end -->
          </body-card>
        </b-col>
      </b-row>
    </div>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { financialSummaryReportApi } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
  import excel from 'vue-excel-export'
  import Vue from 'vue'
  import { ValidationObserver } from 'vee-validate'
  Vue.use(excel)
  export default {
    mixins: [ModalBaseMasterList],
    components: {
    ListReportHead,
        ValidationObserver
    },
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        search: {
          service_ids: [],
          application_type: 0,
          status: 6,
          fiscal_year_id: 0,
          payment_type: 0,
          from_date: '',
          to_date: ''
        },
        searchHeaderData: {
          service_ids: [],
          application_type: 0,
          fiscal_year_id: 0,
          payment_type: 0,
          from_date: '',
          to_date: ''
        },
        datas: [],
        vat: '',
        total_amount: '',
        showData: false,
        districtList: [],
        corporationList: [],
        upazilaList: []
      }
    },
    created () {
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      },
      'search.division_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.districtList = this.getDistrictList(newVal)
        } else {
          this.districtList = []
        }
      },
      'search.district_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.upazilaList = this.getUpazilaList(newVal)
          this.corporationList = this.getcityCorporationList(newVal)
        } else {
          this.upazilaList = []
          this.corporationList = []
        }
      }
    },
    computed: {
        paymentTypeList (id) {
          return [
              { text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline', value: 'offline', text_en: 'Offline', text_bn: 'অফলাইন' },
              { text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online', value: 'online', text_en: 'Online', text_bn: 'অনলাইন' }
          ]
        },
        serviceList: function () {
          return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => JSON.parse(item.service_type).includes(1)).map(item => {
            if (item.value === 30 || item.value === 31 || item.value === 32 || item.value === 33 || item.value === 4 || item.value === 3 || item.value === 1 || item.value === 34) {
              return Object.assign({}, item)
            }
          }).filter(item => item !== undefined)
        },
        statusList () {
          return [
            { value: 6, text: this.$i18n.locale === 'bn' ? 'অনুমোদিত' : 'Approved' },
            { value: 7, text: this.$i18n.locale === 'bn' ? 'প্রত্যাখ্যান' : 'Reject' }
          ]
        },
        gardenList () {
          return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
        },
        fiscalYearList () {
          return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).sort((a, b) => b.sorting_order - a.sorting_order)
        },
        divisionList () {
          return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
        },
        currentLocale () {
            return this.$i18n.locale
        },
        applicationType () {
          return [
            { value: 1, text: this.$i18n.locale === 'bn' ? 'নতুন নিবন্ধন' : 'New Registration', text_en: 'New Registration', text_bn: 'নতুন নিবন্ধন' },
            { value: 2, text: this.$i18n.locale === 'bn' ? 'নবায়ন' : 'Renew', text_en: 'Renew', text_bn: 'নবায়ন' }
          ]
        },
        excelParams () {
          const local = this.$i18n.locale
          const queryParams = Object.keys(this.search)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.search[key])}`)
            .join('&')
            // const updatedParams = `${queryParams}&request_type=excel`
          return `${teaGardenServiceBaseUrl}btb/report/financial-summery-excel/?${queryParams}&local=${local}`
          // return `${teaGardenServiceBaseUrl}btb/report/financial-summary-license-report/?${queryParams}&lang=${lang}`
          // return `${teaGardenServiceBaseUrl}btb/report/financial-summary-license-report/?${updatedParams}&lang=${lang}`
        }
    },
    methods: {
      districtRowSpan (checkData) {
        let total = 0
        checkData.forEach((element, key) => {
          element.garden_fact_info.forEach((element2, key2) => {
            total++
          })
        })
        return total
      },
      async pdfExport () {
          const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
          const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
          if (service !== undefined) {
            if (service.office_type_id) {
              params.office_type_id = service.office_type_id
            }
            if (service.office_id) {
              params.office_id = service.office_id
            }
          }
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, financialSummaryReportApi, params)
          var blob = new Blob([result], {
            type: 'application/pdf'
          })
          var url = window.URL.createObjectURL(blob)
          window.open(url).print()
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
        async excelExport () {
        const params = Object.assign({ local: this.$i18n.locale, org_id: 5, service_ids: JSON.stringify(this.search.service_ids) }, this.search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, 'btb/report/financial-summery-excel', params)
        var blob = new Blob([result], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        var url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'daily-market-retail-price-report.xlsx')
        document.body.appendChild(link)
        link.click()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
        this.showData = true
        const params = Object.assign({}, this.search)
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, this.search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, financialSummaryReportApi, params)
        if (result.success) {
          this.datas = result.data
          this.vat = result.vat
          this.total_amount = result.total_amount
        } else {
          this.datas = []
          this.total_amount = 0
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      getServiceName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === id)
        if (obj) {
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        }
      },
      getFiscalYearName (id) {
        const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
        if (obj) {
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        }
      },
      getUpazilaList (id) {
        return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
      },
      getcityCorporationList (id) {
        return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === id)
      },
      getDivisionName (id) {
          const data = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === id)
          if (typeof data !== 'undefined') {
            return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
          } else {
            return ''
          }
      },
      getGardenName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(data => data.value === id)
          if (obj !== undefined) {
            if (this.$i18n.locale === 'bn') {
              return obj.text_bn
            } else {
              return obj.text_en
            }
         }
      },
      getDistrictName (id) {
        const obj = this.$store.state.CommonService.commonObj.districtList.find(data => data.value === id)
          if (obj !== undefined) {
            if (this.$i18n.locale === 'bn') {
              return obj.text_bn
            } else {
              return obj.text_en
            }
         }
      },
      getDistrictList (divisionId) {
        return this.$store.state.CommonService.commonObj.districtList.filter(item => item.division_id === parseInt(divisionId))
      },
      getApplicationTypeName (id) {
        const obj = this.applicationType.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getPaymentTypeName (id) {
        const obj = this.paymentTypeList.find(item => item.value === id)
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getServiceNames (ids) {
        const nameEnArr = []
        const nameBnArr = []
        ids.forEach(id => {
          const obj = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === parseInt(id))
          if (obj !== undefined) {
            nameEnArr.push(obj.text_en)
            nameBnArr.push(obj.text_bn)
          }
        })
        return this.currentLocale === 'en' ? nameEnArr.join(',') : nameBnArr.join(',')
      }
    }
  }
  </script>
